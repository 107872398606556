<template>
    <div class="edit-stock">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('forms.submit')"
            :title="$t('messages.stockItemsUpdate')"
            @submitPressed="submitPressed"
        />
        <b-card no-body>
            <b-tabs v-model="tabIndex" card content-class="mt-3" ref="tabs">
                <b-tab v-if="$helper.userAdditionalCapability('stock_info')" :title="$t('forms.info')" key="info" active>
                    <stock-form
                        v-if="stock"
                        :stockObject="stock"
                        :action="action"
                        @clearAction="action = null"
                    />
                </b-tab>
                <b-tab v-if="$helper.userAdditionalCapability('stock_files')" :title="$t('forms.files')" key="files">
                    <files-form :resource="$StockItems" :resource-id="$route.params.id" message="stockItemsUpdated"
                                :file-tags="['stock_item']"/>
                </b-tab>
                <b-tab v-if="$helper.userAdditionalCapability('stock_use')" :title="$t('forms.use')" key="use">
                    <usage-form
                        v-if="usage"
                        :stockObject="usage"
                        :action="action"
                        :stockItem="'/stock_items/' + $route.params.id"
                        @clearAction="action = null"
                    />
                </b-tab>
                <b-tab v-if="$helper.userAdditionalCapability('stock_transfer')" :title="$t('forms.transfer')" key="transfer">
                    <transfer-form
                        v-if="transfer"
                        :stockObject="transfer"
                        :stockItem="'/stock_items/' + $route.params.id"
                        :action="action"
                        @clearAction="action = null"
                    />
                </b-tab>
                <b-tab v-if="$helper.userAdditionalCapability('stock_used_items')" :title="$t('navigations.usedItems')" key="usedItems">
                    <used-items :id="$route.params.id"/>
                </b-tab>
                <b-tab v-if="$helper.userAdditionalCapability('stock_transfer_items')" :title="$t('navigations.transferredItems')" key="transferred">
                    <transferred-items :id="$route.params.id"/>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import StockForm from "./components/StockForm.vue";
import UsageForm from "./components/UsageForm.vue";
import TransferForm from "./components/TransferForm.vue";
import FilesForm from "@/components/Files/FilesForm.vue";
import UsedItems from "./components/UsedItems.vue";
import TransferredItems from "./components/TransferredItems.vue";

export default {
    components: {
        TitleBar,
        StockForm,
        UsageForm,
        TransferForm,
        FilesForm,
        TransferredItems,
        UsedItems,
    },
    data() {
        return {
            tabIndex: 0,
            stock: null,
            usage: null,
            transfer: null,
            action: null
        };
    },
    created() {
        this.load();
    },
    mounted() {
        if (this.$route.query.use === 'true' && this.$refs['tabs']) {
            for (let i = 0; i < this.$refs['tabs'].tabs.length; i++) {
                const element = this.$refs['tabs'].tabs[i];
                if(element.tab.key === 'use') {
                    this.tabIndex = i
                }
            }
        }
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$StockItems.getResource({id}).then((response) => {
                this.stock = response.data;
                this.stock.expiryDate = new Date(response.data.expiryDate)
            });
            this.usage = {};
            this.transfer = {};
        },
        submitPressed() {
            if (this.$refs['tabs'].tabs[this.tabIndex].tab.key === 'info') {
                this.action = "update";
            }
            if (this.$refs['tabs'].tabs[this.tabIndex].tab.key === 'use') {
                this.action = "updateUsage";
            }
            if (this.$refs['tabs'].tabs[this.tabIndex].tab.key === 'transfer') {
                this.action = "updateTransfer";
            }
        },
    },
};
</script>
