<template>
    <div>
        <div v-if="rows" class="custom-table">
            <table-actions
                :actions="['perPage']"
                @perPageChange="perPageChange"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:createdAt="data">
                    {{ $helper.formatDate(data.value.createdAt) }}
                    {{ $helper.formatTime(data.value.createdAt) }}
                </template>
                <template v-slot:employee="data">
                    {{
                        data.value.employee.givenName + " " + data.value.employee.familyName
                    }}
                </template>
                <template v-slot:remark="data">
                    {{ data.value.remark }}
                </template>
                <template v-slot:quantity="data">
                    {{ data.value.quantity }}
                </template>
                <template v-slot:type="data">
                    <b-badge variant="primary">{{ data.value.type.replace('_', ' ') }}</b-badge>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
    name: "AdminUsedItemsListing",
    components: {
        TableLite,
        TablePagination,
        TableActions,
    },
    props: {
        id: {type: Number, required: true},
    },
    mixins: [ResourceUtils],
    data() {
        return {
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            sortable: {
                order: "date",
                sort: "asc",
            },
            columns: [
                {
                    label: this.$t("forms.createdAt"),
                    field: "createdAt",
                    sortable: true,
                },
                {
                    label: this.$t("forms.remark"),
                    field: "remark",
                    sortable: false,
                },
                {
                    label: this.$t("forms.quantity"),
                    field: "quantity",
                    sortable: false,
                },
                {
                    label: this.$t("forms.employee"),
                    field: "employee",
                    sortable: true,
                },
                {
                    label: this.$t("forms.usage"),
                    field: "type",
                    sortable: true,
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
                stockItem: this.id
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            this.$UsedItems.getCollection({params}, "used_items").then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.sort = sort;
            this.sortable.order = order;
            if (order === 'employee') {
                this.sortable.order = order + '.familyName';
            }
            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>
